<template>
  <section class="section" style="margin-top: 100px;">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-8" style="padding-bottom: 0px;">
          <h3 class="mobile-adapt-h3" style="color:#ff9425"> making music easier </h3>
          <h2 style="color: #212124"> how fonz works </h2>
          <p> fonz music is an app that, when paired with our coaster, enables guests
          at your parties or gatherings to play their own music, and see what's playing at the moment. </p>
        </div>


        <div class="col-md-6 col-sm-12" style="padding-top: 0px;">
          <h4 style="color:#ff9425"> song requests </h4>
          <p> fonz music allows your guests to choose the next song to play - this is achieved through your spotify 'queue'. no more phone unlocking, no awkward conversations, and no time wasted. 
          everyone is able to play what they want, when they want, easily, all while you're still in control. </p>
        </div>
        <div class="col-md-6 col-sm-12" style="padding-top: 0px;">
          <h4 style="color:#ff9425"> what's playing </h4>
          <p> with the new version of our app, there's no need to shazam or interrupt a conversation to see what's playing at the moment. if you're connected to a 
          party, simply open the fonz app and check the 'now playing' tab. </p>
        </div>
        <div class="col-12">
          <img src="@/assets/images/SplashImages/phoneDiagram.svg"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "howFonzWorks"
}
</script>

<style>
.section-background {
  background-color: white;
  padding: 18px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.09) 0 6px 9px 0; 
}
</style>