<template>
  <section class="section">
    <div class="section-inner">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div style="border:solid 1px; margin: 2px; padding: 5px 70px 5px 70px; border-color: lightgrey; border-radius: 5px;">
              <div class="container mobile-adapt">

                <div class="row-12 d-flex justify-content-center"  style="margin-bottom: 20px;">
                  <span style="font-weight: 900; font-size: 24px; color: grey;"> i'm a host </span>
                </div>

                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialAppStore.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> download our app </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Navigate to the App Store or Play Store and download the Fonz Music App </span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialSpotify.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> connect your spotify </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Sign into your Spotify account on the Fonz Music App </span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialCoaster.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> connect your coasters </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Tap each of your Fonz coasters to pair them to your phone and set yourself as host </span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialQueue.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> select your tune </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Search up the first song of the session using Spotify (music needs to be playing for it to work!) </span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialRockOn.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> rock on! </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Your music is now queued! Enjoy the party! </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          
          <div class="col-md-6 col-sm-12">
            <div style="border:solid 1px; margin: 2px; padding: 5px 70px 5px 70px; border-color: lightgrey; border-radius: 5px;">
              <div class="container">

                <div class="row-12 d-flex justify-content-center"  style="margin-bottom: 20px;">
                  <span style="font-weight: 900; font-size: 24px; color: grey;"> i'm a guest </span>
                </div>

                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialAppStore.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> download our app </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Navigate to the App Store or Play Store and download the Fonz Music App </span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialSignUp.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> sign up </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Create a Fonz account and head into the app! Just use your email!</span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialCoaster.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> connect to the host's coaster </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Tap your phone on the host's coaster to join their session! </span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialQueue.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> select your tune </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Search up the song you want to play using our built-in search feature! Make sure music's already playing! </span>
                    </div>
                  </div>
                </div>


                <div class="row-12 reveal-from-bottom">
                  <div class="col">
                    <div class="row-12 d-flex justify-content-center" style="padding-bottom: 18px;">
                      <img width="50px" src="@/assets/images/Icons/tutorialRockOn.svg"/>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="font-weight: 700; font-size: 18px; color: grey;"> rock on! </span>
                    </div>
                    <div class="row-12 d-flex justify-content-center">
                      <span style="text-align: center; font-size: 16px;"> Your music is now queued! Enjoy the party! </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Tutorial"
}
</script>

<style scoped>
@media (max-width: 768px) {
  .mobile-adapt {
		text-align: center !important;
    margin: auto !important; 
  }
}
</style>
