<template>
    <section class="section"  style="margin-top: 150px;">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-md-8 col-sm-12">
                    <div class="row-12" style="text-align: center;">
                        <span style="font-size: 24px; font-weight: 700; color: grey;"> Join the party! </span>
                    </div>
                    <div class="row-12" style="text-align: center;">
                        <span style="font-size: 24px; font-weight: 700; color: grey;"> <span style="color: #FF9425;"> 386 </span> Registered Users </span> 
                    </div>
                    <div class="row-12" style="text-align: center;">
                        <span style="font-size: 14px;"> (and counting) </span> 
                    </div>
                    <div class="row-12" style="padding-top: 25px; padding-bottom: 25px;">
                        <img src="@/assets/images/homeMap.svg"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Map",
}
</script>

<style scoped>

</style>