<template>
  <section class="section">
      <div class="section-inner contact-head">
          <div class="contact-subheader">
              want to learn more?
          </div>
            <div class="contact-header">
                contact us
            </div>
            <div class="contact-text">
                <a href="mailto:contact@fonzmusic.com">
                    contact@fonzmusic.com
                </a>
            </div>
      </div>
  </section>
</template>

<script>
export default {
    name:'AboutContact',
}
</script>

<style>
.contact-head {
    text-align: center;
}

.contact-subheader {
    color: #ff9425;
    font-size: 16px;
}

.contact-header {
    font-size: 28px;
    color: #363636;
    font-weight: 700;
}

.contact-text {
    display: block;
    margin: 0 auto;
    font-size: 14px;
    width: 300px;
    color: #707070;
}
</style>