<template>
    <div class="button-group">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'CButtonGroup'
}
</script>
