var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"clients section reveal-fade",class:[
        _vm.topOuterDivider && 'has-top-divider',
        _vm.bottomOuterDivider && 'has-bottom-divider',
        _vm.hasBgColor && 'has-bg-color',
        _vm.invertColor && 'invert-color'
    ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"clients-inner section-inner",class:[
                _vm.topDivider && 'has-top-divider',
                _vm.bottomDivider && 'has-bottom-divider'
            ]},[_c('ul',{staticClass:"list-reset"},[_c('li',{staticClass:"reveal-from-top"},[_c('c-image',{attrs:{"src":require('@/assets/images/ClientIcons/clients-01.svg'),"alt":"Client 01","width":124,"height":24}})],1),_c('li',{staticClass:"reveal-from-bottom",attrs:{"data-reveal-delay":"150"}},[_c('c-image',{attrs:{"src":require('@/assets/images/ClientIcons/clients-02.svg'),"alt":"Client 02","width":83,"height":30}})],1),_c('li',{staticClass:"reveal-from-top",attrs:{"data-reveal-delay":"300"}},[_c('c-image',{attrs:{"src":require('@/assets/images/ClientIcons/clients-03.svg'),"alt":"Client 03","width":125,"height":39}})],1),_c('li',{staticClass:"reveal-from-bottom",attrs:{"data-reveal-delay":"450"}},[_c('c-image',{attrs:{"src":require('@/assets/images/ClientIcons/clients-04.svg'),"alt":"Client 04","width":150,"height":31}})],1),_c('li',{staticClass:"reveal-from-top",attrs:{"data-reveal-delay":"600"}},[_c('c-image',{attrs:{"src":require('@/assets/images/ClientIcons/clients-05.svg'),"alt":"Client 05","width":113,"height":30}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }